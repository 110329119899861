import React, { useMemo } from "react";
import { FixedPxBox } from "@novel/shared/components/Box";
import { createGlobalStyle } from "styled-components";
import { DynamicSharedPassPreviewStyle, SharedPassPreviewStyle } from "@novel/shared/components/PassPreview/sharedStyle";
import { StoreCreditCurrencyCode } from "@novel/shared/utils/formatMoney";
import { IRewardsTierCreate } from "@novel/shared/interfaces/RewardsTier";
import { Threedot } from "@novel/shared/icons";
import { AbsoluteCenteredLoader } from "@novel/shared/components/CenteredLoader";
import { PassAnimation } from "@novel/shared/components/PassPreview/shared/PassAnimation";
import { CurrencyCode } from "@novel/shared/__generated__/graphql";
import { IOrgHandle } from "@novel/shared/interfaces/OrgHandle";
import { IOrgCustomerRelationshipWithRewardsStatus } from "@novel/shared/interfaces/OrgCustomerRelationship";
const AppleInstallPassPreviewStyle = createGlobalStyle`
    .circleButton {
        width: 18.34px;
        height: 18.34px;
        border: 1.5px solid #000000;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 4px;
        color: black;
        margin-left: auto;
        margin-bottom: 5px;
        vertical-align: middle;
    }
    .button-big {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 7px 30px;
        background: #000000;
        border-radius: 100px;
        height: 54px;
        font-weight: 500;
        width: 250px;
        font-size: 14px;
        color: white;
    }
    .install-pass-animation {
        width: 270.6px;
        height: 301.57px;
    }
`;
export function AppleInstallPassPreview(props: Omit<IRewardsTierCreate, "tierIndex"> & {
  readonly currentOrgHandle?: IOrgHandle;
  readonly userDisplayName?: string;
  readonly currencyCode?: StoreCreditCurrencyCode;
  readonly passAlreadyInstalled?: boolean;
  readonly referredBy?: IOrgCustomerRelationshipWithRewardsStatus;
  readonly passIsLoading?: boolean;
  readonly openPass?: () => Promise<void>;
}): JSX.Element {
  const {
    passIsLoading,
    passAlreadyInstalled,
    currentOrgHandle,
    backgroundColor,
    textColor,
    referredBy,
    openPass
  } = props;
  return <React.Fragment>
            <FixedPxBox className="main-overall-wrap" maxHeight="100%" width="100%" maxWidth="100%" justifyContent="center" alignItems="center" column flexGrow={1} py={1.15}>
                {currentOrgHandle && <PassAnimation className="install-pass-animation" orgHandle={currentOrgHandle} rewardsTier={props} referredBy={referredBy} currencyCode={props.currencyCode || CurrencyCode.Usd} />}
                <FixedPxBox className="pass-explanation-section" column alignItems="center" justifyContent="center" pb={5}>
                    <FixedPxBox mb={1.35} alignItems="center" justifyContent="center">
                        <div className="pass-line">
                            <span>
                                {passAlreadyInstalled ? "You can view your pass " : "Once the pass is "}
                                in your wallet! Click the
                            </span>
                            <button className="circleButton" style={useMemo(() => ({
              display: "inline-flex",
              margin: "3px"
            }), [])}>
                                <Threedot style={useMemo(() => ({
                filter: "grayscale(100%)"
              }), [])} />
                            </button>
                            <span>button to view perks.</span>
                        </div>
                    </FixedPxBox>
                    <button className="button-big" disabled={passIsLoading} onClick={!passIsLoading ? openPass : undefined}>
                        {passIsLoading && <AbsoluteCenteredLoader />}
                        {passAlreadyInstalled ? "Access Your Pass" : "Get Your Pass!"}
                    </button>
                </FixedPxBox>
            </FixedPxBox>
            <AppleInstallPassPreviewStyle />
            <SharedPassPreviewStyle />
            <DynamicSharedPassPreviewStyle backgroundColor={backgroundColor} textColor={textColor} />
        </React.Fragment>;
}